import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { GlobalStyle } from "./GlobalStyle";
import ReactModal from "react-modal";
import { Loader } from "./Loader/Loader";
import { Toaster } from "react-hot-toast";
import { toastOptions } from "../other/toastOptions";
import Layout from "./Layout/Layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactModal.setAppElement("#root");

const HomePage = lazy(() => import("../pages/Home/Home"));
const PrivacyPolicy = lazy(() =>
  import("../pages/PrivacyPolicy/PrivacyPolicy")
);
const CareersPage = lazy(() => import("../pages/Careers/Careers"));
const StaffSelectionPage = lazy(() =>
  import("../pages/StaffSelection/StaffSelection")
);
const ContactsPage = lazy(() => import("../pages/Contacts/Contacts"));
const VacanciesPage = lazy(() => import("../pages/Vacancies/Vacancies"));

export const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <GlobalStyle />
      <Suspense fallback={<Loader className="apploader" />}>
        <Routes>
          <Route path={"/"} element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="careers/*"
              element={
                <Routes>
                  <Route index element={<CareersPage />} />
                  <Route path="/:vacId" element={<VacanciesPage />} />
                </Routes>
              }
            />
            <Route path="staff-selection" element={<StaffSelectionPage />} />
            <Route path="contacts" element={<ContactsPage />} />
          </Route>
          <Route path="*" element={<Layout />} />
        </Routes>
      </Suspense>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={toastOptions}
        containerStyle={{
          top: 60,
          right: 20,
        }}
      />
    </>
  );
};
